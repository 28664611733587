/* You can add global styles to this file, and also import other style files */

html,
body {
    display: contents;
    height: calc(100vh - 77px)
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    background-color: #F8F8F8;
    color: #212121;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
}

.material-symbols-filled {
    font-variation-settings:
        'FILL' 1,
        'wght' 400,
        'GRAD' 0,
        'opsz' 48
}

.material-symbols-outlined {
    font-variation-settings:
        'FILL' 0,
        'wght' 400,
        'GRAD' 0,
        'opsz' 48
}

.back-header {
    color: white;
    position: absolute;
    right: 25px;
    display: inline-block;
    text-decoration: none;
    font-weight: 500;
}

.back-header mat-icon {
    vertical-align: middle;
    margin-right: 2px;
    font-size: 21px;
}

/*********************************MATERIAL INPUT********************************************/

.mat-mdc-form-field-flex {
    padding: 0 0.75em 0 0.75em;
}

.mat-progress-spinner circle,
.mat-spinner circle {
    stroke: #85C443 !important;
}

mat-spinner {
    width: 20px !important;
    height: 20px !important;
    margin-left: 15px;
}

mat-spinner svg {
    width: 20px !important;
    height: 20px !important;
}

.mat-primary .mat-mdc-progress-spinner .mdc-circular-progress__indeterminate-circle-graphic {
    stroke: white !important;
}

.mdc-notched-outline__leading, .mdc-notched-outline__notch, .mdc-notched-outline__trailing {
    border-color: #85C443 !important;
}

/* Font color */
input.mat-mdc-input-element {
    color: #212121 !important;
}

.filters .mat-form-field-appearance-outline .mat-form-field-outline {
    color: #A6A6A629;
}

mat-form-field {
    width: 100%;
    font-size: 14px !important;
}

.mat-mdc-form-field-infix {
    min-height: 54px !important;
}

.mdc-checkbox-checked.mdc-accent .mdc-checkbox__background, .mat-mdc-checkbox.mat-accent.mdc-checkbox--selected.mdc-checkbox__ripple {
    background-color: #85C443 !important;
    background: #85C443 !important;
}

.mat-mdc-checkbox.mat-accent {
    --mdc-checkbox-selected-focus-icon-color: #85C443 !important;
    --mdc-checkbox-selected-hover-icon-color: #85C443 !important;
    --mdc-checkbox-selected-icon-color: #85C443 !important;
    --mdc-checkbox-selected-pressed-icon-color: #85C443 !important;
    --mat-mdc-checkbox-checked-ripple-color: #85C443 !important;
}

.mat-mdc-checkbox.mat-accent .mdc-checkbox--selected~.mdc-checkbox__ripple {
    background: #85C443;
}
    

.mat-mdc-radio-button.mat-accent {
    --mdc-radio-selected-focus-icon-color: #85C443;
    --mdc-radio-selected-hover-icon-color: #85C443;
    --mdc-radio-selected-icon-color: #85C443;
    --mdc-radio-selected-pressed-icon-color: #85C443;
    --mat-mdc-radio-checked-ripple-color: #85C443;
}

.mat-mdc-checkbox.mdc-checkbox:hover.mdc-checkbox__native-control:enabled:checked.mdc-checkbox__background {
    background-color: #85C443 !important;
    border-color: #85C443 !important;
}

.mat-mdc-slide-toggle.mat-mdc-checked .mat-mdc-slide-toggle-thumb {
    background-color: #85C443 !important;
}

.mat-mdc-slide-toggle.mat-checked .mat-mdc-slide-toggle-bar {
    background-color: #cee7b3 !important;
}

.mat-primary .mat-pseudo-checkbox-checked, .mat-primary .mat-pseudo-checkbox-indeterminate {
    background: #85C443 !important;
}

.mat-mdc-checkbox .mdc-checkbox {
    padding: calc((var(--mdc-checkbox-state-layer-size, 40px) - 20px) / 2) !important;
    margin: calc((var(--mdc-checkbox-touch-target-size, 2px) - 18px) / 2) !important;
}

.mat-mdc-primary .mat-mdc-option.mat-mdc-selected:not(.mat-mdc-option-disabled) {
    color: #85C443;
}
.mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
    color: #85C443 !important;
}

.loading-list .mat-mdc-progress-spinner circle,
.loading-list .mat-mdc-spinner circle {
    stroke: #85C443;
}

.outline-spinner input {
    display: inline-block;
    width: calc(100% - 12px);
}

.outline-spinner mat-spinner {
    width: 12px !important;
    height: 12px !important;
    display: inline-block;
}

.outline-spinner mat-spinner svg {
    width: 12px !important;
    height: 12px !important;
}

.outline-spinner .mat-progress-spinner circle,
.outline-spinner .mat-spinner circle {
    stroke: #426221 !important;
}

.ubs .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
    border-bottom-color: #F6CD48 !important;
    border-bottom-width: medium !important;
}

.ubs .mat-mdc-form-field-subscript-wrapper {
    height: 5px;
}

.ubs .mat-mdc-form-field-flex {
    padding: 0;
}

.box-in-sharing.ubs .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
    padding-bottom: 2px;
}

/*********************************END MATERIAL INPUT********************************************/

/*********************************BUTTONS********************************************/
button {
    letter-spacing: 0px !important;
}

.btn-back, .btn-go {
    font-family: 'Inter', 'Dosis' !important;
    font-style: normal !important;
    line-height: 24px !important;
    font-size: 24px !important;
    font-weight: 900 !important;
    text-transform: uppercase !important;
    margin-bottom: 10px !important;
    border-radius: 32px !important;
    padding: 7px !important;
    position: absolute !important;
    bottom: 27px !important;
    width: 369px !important;
    height: 70px !important;
    padding: 16px 32px !important;
}

.btn-back {
    color: #37454E !important;
    background-color: #F6CD48 !important;
    left: 34px !important;
}

.btn-go {
    background-color: #60B667 !important;
    right: 34px !important;
    color: white !important;
    /* padding-left: 20px; */
}

.btn-back:disabled, .btn-go:disabled {
    background-color: #C4C4C4 !important;
}

.btn-go:disabled {
    color: #37454E !important;
}

button .mat-icon {
    color: #37454E !important;
    font-weight: bolder;
    height: 28px;
    font-size: 28px;
}

.btn-go .mat-icon{
    padding-left: 17px;
}

.btn-back .mat-icon{
    padding-right: 17px;
}

.mat-mdc-unelevated-button.mat-primary {
    --mdc-filled-button-container-color: #85C443 !important;
    --mdc-filled-button-label-text-color: #fff;
}

.btn-primary {
    background-color: #85C443 !important;
    color: white !important;
    margin-bottom: 10px !important;
}

.btn-primary:hover,
.btn-primary:focus {
    background-color: #4f7528;
    color: white;
}

.btn-primary.mat-flat-button.mat-accent,
.btn-primary.mat-raised-button.mat-accent,
.btn-primary.mat-fab.mat-accent,
.btn-primary.mat-mini-fab.mat-accent {
    background-color: #85C443;
}

.btn-primary .mat-progress-spinner circle,
.btn-primary .mat-spinner circle {
    stroke: white;
}

.btn-primary mat-spinner {
    margin: auto;
    margin-top: 3px;
    margin-bottom: 3px;
}

.btn-primary.block {
    display: block;
    width: 100%;
}

.btn-primary.add-user {
    margin-bottom: 30px !important;
}

.btn-primary:disabled {
    background-color: #e6e6fa;
}

.btn-secundary {
    color: #85C443 !important;
    background-color: white !important;
    border-color: #85C443 !important;
    margin-bottom: 10px !important;
}

.btn-secundary.block {
    display: block;
    width: 100%;
}

.btn-block {
    width: 100% !important;
    height: 36.3px !important;
}

.back-link {
    color: #212121;
    text-decoration: none;
    display: none;
    margin-bottom: 10px;
}

.back-link:hover,
.back-link:focus {
    color: #212121;
    text-decoration: none;
}

.back-link mat-icon {
    color: #85C443;
    vertical-align: bottom;
    font-size: 20px;
}

.ubs .mat-mdc-button.mat-mdc-button-disabled.mat-mdc-button-disabled {
    background-color: #C4C4C4;
    color: #38464D;
}

/* .button-container {
    position: relative;
    position: absolute;
    bottom: 0;
    z-index: 99;
    width: 100%;
    display: flex;
    justify-content: space-between;
} */

/*******************************************RADIO*****************************************/
.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
    background-color: #85C443;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: #85C443;
}

/* .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:not(:checked)+.mdc-radio__background .mdc-radio__outer-circle {
    width: 49px;
    height: 49px;
    border: 3px solid #DA6960;
} */

.mat-radio-label-content {
    padding-left: 4px !important;
    padding-right: 14px !important;
}

.mat-radio-button {
    margin-bottom: 15px;
}

.copy-to-clipboard {
    border: none;
    color: #85C443;
    background-color: transparent;
    line-height: unset;
}

.copy-to-clipboard:hover {
    opacity: 0.85;
}

.copy-icons {
    font-size: 16px;
}
.mat-mdc-radio-button .mdc-radio {
    padding: calc((var(--mdc-radio-state-layer-size, 30px) - 20px) / 2) !important;
}
.mat-mdc-radio-button.mat-accent {
    --mdc-radio-selected-focus-icon-color: #85C443 !important;
    --mdc-radio-selected-hover-icon-color: #85C443 !important;
    --mdc-radio-selected-icon-color: #85C443 !important;
    --mdc-radio-selected-pressed-icon-color: #85C443 !important;
    --mat-mdc-radio-checked-ripple-color: #85C443 !important;
}


.radio-ubs mat-radio-button .mdc-form-field .mdc-radio, 
.radio-ubs .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control,
.radio-ubs .mdc-radio__background {
    width: 49px !important;
    height: 49px !important;
}

.radio-ubs.mat-mdc-radio-button.mat-mdc-accent.mat-mdc-radio-checked .mat-mdc-radio-outer-circle {
    border-color: #65B4BA;
}

.radio-ubs .mat-mdc-radio-button.mat-mdc-accent .mat-mdc-radio-inner-circle {
    color: #65B4BA;
    background-color: #65B4BA;
}

.radio-ubs .mat-mdc-button.mat-mdc-button-disabled.mat-mdc-button-disabled {
    background-color: #C4C4C4;
    color: #38464D;
}

.radio-ubs .mdc-radio__native-control:checked+.mdc-radio__background .mdc-radio__inner-circle {
    transform: scale(0.6) !important;
}

.radio-ubs .mdc-radio__inner-circle {
    border-width: 24px !important;
}

.radio-ubs .mat-mdc-radio-button.mat-accent {
    --mdc-radio-selected-focus-icon-color: #37454E !important;
    --mdc-radio-selected-hover-icon-color: #37454E !important;
    --mdc-radio-selected-icon-color: #37454E !important;
    --mdc-radio-selected-pressed-icon-color: #37454E !important;
    --mat-mdc-radio-checked-ripple-color: transparent !important;
}

.radio-ubs .mat-mdc-checkbox.mat-accent .mdc-checkbox--selected~.mdc-checkbox__ripple {
    background: transparent;
}

.radio-ubs .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:not(:checked)+.mdc-radio__background .mdc-radio__outer-circle{
    border-color: #F6CD48 !important;
}

.radio-ubs .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:checked+.mdc-radio__background .mdc-radio__outer-circle{
    border-color: #F6CD48 !important;
}

/********************************************END RADIO************************************/


/*********************************END BUTTONS********************************************/




/*********************************LAYOUT********************************************/
.container-body {
    padding: 92px 20px 20px 76px;
    height: calc(100vh);
}

.container-body-secundary-header {
    padding: 195px 20px 20px 76px;
    height: calc(100vh);
}

.white-body {
    background-color: white;
    box-shadow: 0px 2px 6px #00000029;
    padding: 20px 15px;
    display: block;
    width: 100%;
    border-radius: 2px;
}

.title-header {
    border-bottom: 1px solid #C6C6C6;
    margin-bottom: 25px;
}

.title-header h1 {
    display: inline-block;
    font-size: 20px !important;
    vertical-align: top;
    margin-bottom: 10px;
}

.title-header mat-icon {
    display: inline-block;
    font-size: 29px;
    color: #85C443;
    margin-right: 11px;
}

.title-header mat-slide-toggle {
    height: 30px;
    margin-left: 10px;
}

/*********************************LAYOUT********************************************/


/*********************************CARD LIST********************************************/
.card-list .card-item {
    padding: 10px 9px;
    border: 1px solid #A6A6A629;
    border-radius: 4px;
    margin-top: 15px;
    border-radius: 4px;
    height: calc(100% - 15px);
}

.card-list .card-item .infos {
    display: inline-block;
    width: calc(100% - 78px);
}

.card-list .card-item .actions {
    display: inline-block;
    width: 78px;
    text-align: right;
    padding-bottom: 4px;
    vertical-align: bottom
}

.card-list .card-item .actions a {
    text-align: center;
    color: #85C443;
    border: 1px solid #85C443;
    border-radius: 50px;
    width: 28px;
    height: 28px;
    display: inline-block;
    margin: 0px 5px;
    cursor: pointer;
    transition: all linear .1s;
}

.card-list .card-item .actions a:hover,
.card-list .card-item .actions a.active {
    background-color: #85C443;
    color: white
}

.card-list .card-item .actions a mat-icon {
    font-size: 17px;
    padding-top: 4px;
}

.card-list .card-item span {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.card-list .card-item span.primary {
    font-size: 15px;
    font-weight: 600;
}

.card-list .card-item span.secundary {
    font-size: 13px;
    color: #C6C6C6
}

.card-list .card-item span.secundary-status {
    font-size: 13px;
    color: #85C443;
}

.card-list .card-item span.last-info {
    padding-top: 10px;
}


/*********************************END CARD LIST********************************************/

/*********************************UTILS********************************************/
.vertical-aling-out {
    display: table;
    height: 100%;
    width: 100%;
}

.vertical-aling-inner {
    display: table-cell;
    vertical-align: middle;
    width: 100%;
}

.container-out {
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 999;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, .2);
    transition: all linear .2s;
}

.bold {
    font-weight: bold;
}

/*********************************END UTILS********************************************/

@media only screen and (max-width: 1000px) and (min-width: 768px) {
    .container-body-secundary-header {
        padding: 195px 20px 20px 76px;
        height: calc(100vh);
    }
}

@media only screen and (max-width: 767px) {
    .container-body {
        height: unset;
        min-height: calc(100vh);
        padding: 76px 10px 10px 10px;
        background-color: white;
    }

    .container-body-secundary-header {
        height: unset;
        min-height: calc(100vh);
        padding: 210px 10px 66px 10px;
        background-color: white;
    }

    .white-body {
        box-shadow: none;
        padding-left: 0px;
        padding-right: 2px;
    }

    .btn-primary,
    .btn-secundary {
        display: block;
        width: 100%;
    }

    .back-link {
        display: inline-block;
    }
}


/* Generic table */
.basic-table {
    width: 100%;
}

.basic-table__head {
    border-bottom: 1px solid #a6a6a680;
    color: #85C443;
    font-weight: 600;
    padding-bottom: 1rem;
}

.basic-table__head>tr>th {
    padding-bottom: 0.8rem;
}

.basic-table__head>tr>th:first-child {
    padding-left: 0.5rem;
}

.basic-table__body>tr>td:first-child {
    padding-left: 0.5rem;
}

.basic-table__body {
    color: #6e6e6e;
}

.basic-table__body>tr:hover {
    background-color: #86c4433d;
}

.basic-table__body__row {
    height: 3rem;
    border-bottom: 1px solid #A6A6A629;
}

.basic-table__body__row__action {
    cursor: pointer;
    display: flex;
    justify-content: space-around;
    padding-right: 1rem;
    color: #A6A6A629;
}

.basic-table__body__row:hover .basic-table__body__row__action {
    color: #6e6e6e;
}

.basic-table__body__row__action>mat-icon {
    margin: 0px 5px;
}

.mobile .basic-table__body__row__action {
    color: #6e6e6e;
}

/* Generic Accordion */

.accordion {
    display: block;
    padding-bottom: 2rem;
}

.accordion-item {
    display: block;
    border: solid 1px #A6A6A629;
    margin-bottom: 1rem;
    box-shadow: 0px 2px 6px #00000029;
}

.accordion-item+.accordion-item {
    border-top: none;
}

.accordion-item-description {
    font-size: 0.85em;
    color: #999;
}

.accordion-item-description-expanded {
    color: white;
}

.accordion-item-header {
    padding: 1rem;
}

.accordion-item-body {
    padding: 0.5rem;
}

.accordion-item:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.accordion-item:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.list-tag {
    display: block;
    margin-bottom: 15px;
}

.list-tag .tag {
    display: inline-block;
    margin-right: 8px;
    background-color: #F8F8F8;
    border-radius: 5px;
    padding: 3px 8px;
    margin-bottom: 8px;
    box-shadow: 0px 1px 1px #00000029;
}

/* Mobile config */

.mobile {
    display: none !important;
}

@media only screen and (max-width: 767px) {
    .desktop {
        display: none;
    }

    .mobile {
        display: block !important;
    }
}

.truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.flex-space-between {
    display: flex;
    justify-content: space-between;
}

.padding-0 {
    padding: 0.5rem;
}

.padding-y {
    padding: 0.5rem 0rem;
}

.padding-x {
    padding: 0rem 0.5rem;
}

.padding-right-0 {
    padding-right: 0.5rem;
}

/************ Material Progress Bar *************/
.mat-progress-bar-fill::after {
    background-color: #85C443;
}

.mat-progress-bar-buffer {
    background: #d4e4ce;
}

/************ END - Material Progress Bar *************/
.offline {
    width: 100%;
    height: 1.5rem;
    background-color: #f0ecb5;
    text-align: center;
    color: #777777;
    top: 3.5rem;
    position: fixed;
    z-index: 99;
}

.offline--icon {
    margin-right: 1rem;
    top: 0.15rem;
    position: relative;
    font-size: 20px;
}

/************ Form Field Toggle *************/

.form-field-toggle {
    text-align: left;
    padding-bottom: 20px;
}

.form-field-toggle>mat-slide-toggle {
    display: block;
    margin-bottom: 5px;
    margin-top: 5px;
}

/************ END - Form Field Toggle *************/



/********************************************** UBS HEALTH PROMOTION **********************************************/

.btn-try-again-error:active {
    box-shadow: 0px 0px 0px 0px;
}

.btn-try-again-error {
    background-color: #F6CD48 !important;
    color: #37454E !important;
    margin-bottom: 10px !important;
    width: 289px !important;
    height: 65px !important;
    font-family: 'Inter', 'Dosis', sans-serif !important;
    font-weight: bold !important;
    font-size: 24px !important;
    border-radius: 35px !important;
    box-shadow: 0px 1px 4px 1px rgb(0 0 0 / 25%);
}

.btn-close-back:active {
    box-shadow: 0px 0px 0px 0px;
}

.btn-close-back {
    background-color: #F6CD48 !important;
    color: #37454E !important;
    margin-bottom: 10px !important;
    font-family: 'Inter', 'Dosis', sans-serif !important;
    width: 182px;
    height: 65px;
    font-weight: bold !important;
    font-size: 24px !important;
    display: grid !important;
    border-radius: 35px !important;
    padding-top: 10px !important;
    box-shadow: 0px 1px 4px 1px rgb(0 0 0 / 25%);
}

.btn-close-back:disabled {
    background-color: #C4C4C4 !important;
}

.btn-close-error:active {
    box-shadow: 0px 0px 0px 0px;
}

.btn-close-error {
    background-color: #C4C4C4 !important;
    color: #37454E !important;
    margin-bottom: 10px !important;
    width: 242px !important;
    height: 65px !important;
    font-family: 'Inter', 'Dosis', sans-serif !important;
    font-weight: bold !important;
    font-size: 24px !important;
    border-radius: 35px !important;
    box-shadow: 0px 1px 4px 1px rgb(0 0 0 / 25%);
}

.loading-ubs-health-promotion-modal .mat-dialog-container, .mdc-dialog__surface {
    border-radius: 50px;
}

app-loading-ubs-health-promotion-modal {
    display: flex;
}

.error-ubs-health-promotion-modal .mat-dialog-container {
    border-radius: 50px;
    background-color: #EAA292;
}

.mat-icon, .audio-icon-title {
    height: auto !important;
    width: auto !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: transparent !important;
}

.btn-ubs .mat-icon {
    color: white !important;
}

.btn-ubs:disabled .mat-icon {
    color: #37454E !important;
}

.container-title {
    margin-bottom: 30px;
}

.mdc-button__label {
    display: flex;
    align-items: center;
}

.ubs .loading .mat-mdc-progress-spinner circle, .mat-mdc-spinner circle {
    stroke: #000000;
}

.ubs .body-form {
    padding-top: 95px;
}

.ubs .white-body-ubs {
    min-height: 70vh;
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: column;
}

.ubs .form-container{
    margin: auto;
    width: 65%;
}

.ubs .mat-mdc-form-field {
    font-weight: 500;
    line-height: 1.125;
    font-family: 'Inter', 'Dosis';
    letter-spacing: normal;
    width: 40vw;
    max-height: 120px;
    font-size: 36px !important;
}

.ubs .containter-ubs {
    align-items: center;
}

.ubs .audio-icon {
    width: 68px;
    height: 68px;
}

@media (max-height: 700px) {
    .ubs .body-form {
        /* padding-top: 15px; */
    }
}

/***************************************************/